import shared from '@/shared/lib/locales/pl/shared.json';

import popups from '@/shared/lib/support/popups/locales/popupsLocalePL.json';
import validation from '@/shared/lib/support/validation/locales/validationLocalePL.json';

import app from '@/app/locales/pl/app.json';

export default {
  ...shared,
  ...popups,
  ...validation,
  ...app,
};
